fetch("/config/remoteModulesConfig.json").then(configResponse => {
    configResponse.json().then(config => {
        // @ts-ignore
        window.remoteUrlProjectMgmt = config.projectMgmt
        // @ts-ignore
        window.remoteUrlMarketplace = config.marketplace
        // @ts-ignore
        window.remoteUrlHelpdesk = config.helpdesk
        import('./bootstrap')
    })
})

export { }